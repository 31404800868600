import confetti from 'canvas-confetti';

export const triggerConfetti = () => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
    zIndex: 1000,
  };

  function fire(particleRatio: number, opts: confetti.Options) {
    confetti({
      ...defaults,
      ...opts,
      particleCount: Math.floor(count * particleRatio),
    });
  }

  // Send confetti from left, middle, and right
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
    origin: { x: 0.2 },
    colors: ['#7c3aed', '#4f46e5', '#2563eb'],
  });

  fire(0.2, {
    spread: 60,
    origin: { x: 0.5 },
    colors: ['#9333ea', '#7c3aed', '#6366f1'],
  });

  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
    origin: { x: 0.8 },
    colors: ['#4f46e5', '#2563eb', '#ffd700'],
  });

  // Send a few bursts of confetti
  setTimeout(() => {
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
      origin: { x: 0.4 },
      colors: ['#7c3aed', '#ffd700', '#2563eb'],
    });
  }, 250);

  setTimeout(() => {
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
      origin: { x: 0.6 },
      colors: ['#9333ea', '#4f46e5', '#ffd700'],
    });
  }, 400);
};