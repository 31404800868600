import React, { createContext, useContext } from 'react';
import { useAuth } from '../lib/supabase';
import LoadingSpinner from './LoadingSpinner';

interface AuthContextType {
  user: any;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  initialized: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  isAuthenticated: false,
  isLoading: true,
  error: null,
  initialized: false
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { user, isLoading, error, isAuthenticated, initialized } = useAuth();

  // Only show loading spinner while Supabase is initializing
  if (!initialized) {
    return <LoadingSpinner />;
  }

  return (
    <AuthContext.Provider value={{
      user,
      isAuthenticated,
      isLoading,
      error,
      initialized
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => useContext(AuthContext);