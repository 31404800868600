import { create } from 'zustand';

interface SpeechStore {
  voice: SpeechSynthesisVoice | null;
  rate: number;
  pitch: number;
  voices: SpeechSynthesisVoice[];
  setVoice: (voice: SpeechSynthesisVoice) => void;
  setRate: (rate: number) => void;
  setPitch: (pitch: number) => void;
  loadVoices: () => void;
}

export const useSpeechStore = create<SpeechStore>((set, get) => ({
  voice: null,
  rate: 0.9,
  pitch: 1,
  voices: [],
  setVoice: (voice) => set({ voice }),
  setRate: (rate) => set({ rate }),
  setPitch: (pitch) => set({ pitch }),
  loadVoices: () => {
    const voices = window.speechSynthesis.getVoices();
    set({ voices });
    // Set default to first English voice, fallback to any voice
    const englishVoice = voices.find(voice => voice.lang.startsWith('en-')) || voices[0];
    if (englishVoice && !get().voice) {
      set({ voice: englishVoice });
    }
  }
}));

// Initialize voices when they become available
if (typeof window !== 'undefined' && window.speechSynthesis) {
  // Initial load attempt
  useSpeechStore.getState().loadVoices();
  
  // Setup event listener for when voices are ready
  speechSynthesis.addEventListener('voiceschanged', () => {
    useSpeechStore.getState().loadVoices();
  });
}

export const speak = (text: string, options?: { 
  rate?: number; 
  pitch?: number;
  voice?: SpeechSynthesisVoice;
}) => {
  if (typeof window === 'undefined' || !window.speechSynthesis) {
    console.warn('Speech synthesis not supported');
    return;
  }

  try {
    // iOS requires cancelling any ongoing speech before starting new one
    window.speechSynthesis.cancel();

    const store = useSpeechStore.getState();
    const utterance = new SpeechSynthesisUtterance(text);
    
    // Apply settings
    utterance.voice = options?.voice || store.voice || null;
    utterance.rate = options?.rate || store.rate;
    utterance.pitch = options?.pitch || store.pitch;

    // iOS Safari fix: add a small delay
    setTimeout(() => {
      window.speechSynthesis.speak(utterance);
    }, 100);
  } catch (error) {
    console.error('Speech synthesis error:', error);
  }
};

export const stopSpeaking = () => {
  if (typeof window !== 'undefined' && window.speechSynthesis) {
    window.speechSynthesis.cancel();
  }
};