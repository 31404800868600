import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, List, Pencil, Trophy, Star, Volume2, Brain, Target } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function HowToUse() {
  const steps = [
    {
      icon: List,
      title: "1. Create Your Word Lists",
      description: "Add your weekly spelling words to personalized lists. You can create multiple lists for different subjects or difficulty levels.",
      tip: "Pro tip: Use the bulk add feature to quickly add multiple words at once!"
    },
    {
      icon: BookOpen,
      title: "2. Practice with Flash Cards",
      description: "Use interactive flash cards to familiarize yourself with the words. Flip cards to see example sentences and hear correct pronunciation.",
      tip: "Click the speaker icon to hear the word spoken clearly."
    },
    {
      icon: Volume2,
      title: "3. Listen and Learn",
      description: "Our voice guidance helps you understand the correct pronunciation. Adjust voice settings to find what works best for you.",
      tip: "You can change the speaking rate and voice in settings."
    },
    {
      icon: Pencil,
      title: "4. Take Practice Tests",
      description: "Test your knowledge with spelling tests. Get immediate feedback and track your progress over time.",
      tip: "Practice regularly for the best results!"
    },
    {
      icon: Trophy,
      title: "5. Track Your Progress",
      description: "Monitor your improvement through the dashboard. Watch your scores improve and earn achievements.",
      tip: "Try to maintain a practice streak for consistent improvement."
    }
  ];

  const features = [
    {
      icon: Brain,
      title: "Personalized Learning",
      description: "Adapt the learning experience to your needs with customizable word lists and voice settings."
    },
    {
      icon: Target,
      title: "Focused Practice",
      description: "Concentrate on specific word lists or challenge yourself with mixed practice sessions."
    },
    {
      icon: Star,
      title: "Achievement System",
      description: "Stay motivated with achievements and progress tracking as you improve your spelling skills."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-blue-50">
      <div className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">
            How to Use <span className="text-purple-600">Spell Jam</span>
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Follow these simple steps to make the most of your spelling practice
          </p>
        </motion.div>

        <div className="max-w-4xl mx-auto space-y-12 mb-16">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="bg-white rounded-xl shadow-lg p-6 md:p-8"
            >
              <div className="flex flex-col md:flex-row gap-6">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
                    <step.icon className="w-6 h-6 text-purple-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">{step.title}</h3>
                  <p className="text-gray-600 mb-4">{step.description}</p>
                  <p className="text-sm text-purple-600 italic">{step.tip}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-800 mb-12">Key Features</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl shadow-lg p-6"
              >
                <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <feature.icon className="w-6 h-6 text-purple-600" />
                </div>
                <h3 className="text-lg font-bold text-gray-800 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="text-center bg-purple-600 text-white rounded-2xl p-8 max-w-2xl mx-auto"
        >
          <h2 className="text-2xl font-bold mb-4">Ready to Start Learning?</h2>
          <p className="mb-6">Join now and improve your spelling skills!</p>
          <Link
            to="/demo"
            className="inline-flex items-center px-6 py-3 bg-white text-purple-600 rounded-full hover:bg-purple-50 transition-colors font-semibold"
          >
            Try Demo
          </Link>
        </motion.div>
      </div>
    </div>
  );
}