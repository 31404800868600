import React, { createContext, useContext, useEffect } from 'react';
import { useWordStore } from '../lib/wordStore';
import { supabase } from '../lib/supabase';
import { useAuth } from '../lib/supabase';

const WordListContext = createContext<null>(null);

export function WordListProvider({ children }: { children: React.ReactNode }) {
  const { user } = useAuth();
  const { fetchWordLists } = useWordStore();

  useEffect(() => {
    if (user) {
      // Initial fetch
      fetchWordLists();

      // Set up real-time subscription
      const channel = supabase
        .channel('word-lists-changes')
        .on(
          'postgres_changes',
          {
            event: '*',
            schema: 'public',
            table: 'word_lists',
            filter: `user_id=eq.${user.id}`
          },
          () => {
            fetchWordLists();
          }
        )
        .subscribe();

      return () => {
        channel.unsubscribe();
      };
    }
  }, [user, fetchWordLists]);

  return (
    <WordListContext.Provider value={null}>
      {children}
    </WordListContext.Provider>
  );
}