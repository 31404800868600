import React from 'react';
import { Volume2, VolumeX } from 'lucide-react';
import { useSpeechStore, speak, stopSpeaking } from '../utils/speech';

export default function SpeechSettings() {
  const { voice, rate, pitch, voices, setVoice, setRate, setPitch } = useSpeechStore();

  const testVoice = () => {
    speak("Welcome to Spell Jam! Let's practice spelling together.");
  };

  return (
    <div className="space-y-4 p-4 bg-white rounded-lg shadow">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Voice
        </label>
        <select
          value={voice?.name || ''}
          onChange={(e) => {
            const selectedVoice = voices.find(v => v.name === e.target.value);
            if (selectedVoice) setVoice(selectedVoice);
          }}
          className="w-full rounded-lg border-gray-300 focus:ring-purple-500 focus:border-purple-500"
        >
          {voices.map((v) => (
            <option key={v.name} value={v.name}>
              {v.name} ({v.lang})
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Speed: {rate.toFixed(1)}x
        </label>
        <input
          type="range"
          min="0.5"
          max="1.5"
          step="0.1"
          value={rate}
          onChange={(e) => setRate(parseFloat(e.target.value))}
          className="w-full"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Pitch: {pitch.toFixed(1)}
        </label>
        <input
          type="range"
          min="0.5"
          max="1.5"
          step="0.1"
          value={pitch}
          onChange={(e) => setPitch(parseFloat(e.target.value))}
          className="w-full"
        />
      </div>

      <div className="flex gap-2">
        <button
          onClick={testVoice}
          className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <Volume2 className="w-4 h-4" />
          Test Voice
        </button>
        <button
          onClick={stopSpeaking}
          className="flex items-center gap-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200 transition-colors"
        >
          <VolumeX className="w-4 h-4" />
          Stop
        </button>
      </div>
    </div>
  );
}