import React, { useEffect, useState } from 'react';
import { supabase } from '../lib/supabase';
import { useAuth } from '../lib/supabase';

export default function DatabaseTest() {
  const { user } = useAuth();
  const [dbStatus, setDbStatus] = useState('Checking...');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!user) {
      setError('Please login first');
      return;
    }

    const testDatabase = async () => {
      try {
        // Test connection with a simple query
        const { data, error } = await supabase
          .from('word_lists')
          .select('id')
          .limit(1);

        if (error) throw error;

        // Test realtime subscription
        const channel = supabase
          .channel('db-health-check')
          .on('presence', { event: 'sync' }, () => {
            setDbStatus('Database connection working! ✅');
          })
          .subscribe();

        // Cleanup subscription
        return () => {
          channel.unsubscribe();
        };
      } catch (err: any) {
        console.error('Database test failed:', err);
        setDbStatus('Database error ❌');
        setError(err.message);
      }
    };

    testDatabase();
  }, [user]);

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Database Status</h2>
      {error ? (
        <p className="text-red-600">{error}</p>
      ) : (
        <div className="space-y-2">
          <p>Supabase Connection: {dbStatus}</p>
        </div>
      )}
    </div>
  );
}