import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Pencil, Volume2, X, Check, Trash2 } from 'lucide-react';
import { useWordStore } from '../lib/wordStore';
import { useAuth } from '../lib/supabase';
import { speak } from '../utils/speech';

function WordLibrary() {
  const { user } = useAuth();
  const { 
    wordLists = [], 
    addWordList,
    addWordsToList,
    deleteWordFromList,
    fetchWordLists,
    isLoading, 
    error 
  } = useWordStore();

  const [showNewListModal, setShowNewListModal] = useState(false);
  const [showBulkAddModal, setShowBulkAddModal] = useState(false);
  const [newListTitle, setNewListTitle] = useState('');
  const [newListDate, setNewListDate] = useState('');
  const [bulkWords, setBulkWords] = useState('');
  const [editingListId, setEditingListId] = useState<string | null>(null);
  const [localError, setLocalError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (user) {
      fetchWordLists();
    }
  }, [user, fetchWordLists]);

  const resetForm = () => {
    setNewListTitle('');
    setNewListDate('');
    setBulkWords('');
    setLocalError(null);
    setShowNewListModal(false);
    setShowBulkAddModal(false);
  };

  const handleAddNewList = async () => {
    if (!newListTitle || !newListDate) {
      setLocalError('Please fill in all fields');
      return;
    }

    try {
      setLocalError(null);
      const listId = await addWordList(newListTitle, newListDate);
      resetForm();
      setEditingListId(listId);
      
      setSuccessMessage('Word list created successfully!');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } catch (err) {
      console.error('Error creating list:', err);
      setLocalError('Failed to create list');
    }
  };

  const handleBulkAddWords = async (listId: string) => {
    if (!bulkWords.trim()) {
      setLocalError('Please enter some words');
      return;
    }

    try {
      setIsProcessing(true);
      setLocalError(null);
      
      const words = bulkWords
        .split('\n')
        .map(word => word.trim())
        .filter(word => word.length > 0);

      await addWordsToList(listId, words);
      
      setBulkWords('');
      setShowBulkAddModal(false);
      setSuccessMessage('Words added successfully!');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 1500);
    } catch (err: any) {
      setLocalError(err.message || 'Failed to add words');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteWord = async (listId: string, word: string) => {
    try {
      await deleteWordFromList(listId, word);
      setSuccessMessage('Word deleted successfully!');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 1500);
    } catch (err: any) {
      setLocalError(err.message || 'Failed to delete word');
    }
  };

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Word Library</h1>
        </div>
        <div className="space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="bg-white rounded-xl shadow-lg p-6 animate-pulse">
              <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
              <div className="h-4 bg-gray-100 rounded w-1/3"></div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <AnimatePresence>
        {showSuccess && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed top-4 right-4 bg-green-500 text-white px-6 py-3 rounded-lg shadow-lg z-50 flex items-center gap-2"
          >
            <Check className="w-5 h-5" />
            {successMessage}
          </motion.div>
        )}
      </AnimatePresence>

      {(error || localError) && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-4 p-4 bg-red-50 text-red-600 rounded-lg flex items-center gap-2"
        >
          <X className="w-5 h-5" />
          {error || localError}
        </motion.div>
      )}

      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold text-gray-800">Word Library</h1>
        <button 
          onClick={() => setShowNewListModal(true)}
          className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <Plus className="w-5 h-5" />
          New List
        </button>
      </div>

      <div className="space-y-6">
        <AnimatePresence>
          {wordLists.map((list) => (
            <motion.div
              key={list.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="bg-white rounded-xl shadow-lg p-6"
              layoutId={list.id}
            >
              <div className="flex justify-between items-center mb-4">
                <div>
                  <h2 className="text-xl font-bold text-gray-800">{list.title}</h2>
                  <p className="text-sm text-gray-600">Test Date: {list.test_date}</p>
                </div>
                <button 
                  onClick={() => {
                    setEditingListId(list.id === editingListId ? null : list.id);
                    setShowBulkAddModal(false);
                  }}
                  className={`p-2 rounded-lg transition-colors ${
                    list.id === editingListId
                      ? 'bg-purple-100 text-purple-600'
                      : 'text-gray-600 hover:text-purple-600'
                  }`}
                >
                  <Pencil className="w-5 h-5" />
                </button>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {list.words?.map((word, index) => (
                  <motion.div
                    key={`${list.id}-${word.word}-${index}`}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    className="flex items-center justify-between bg-gray-50 rounded-lg p-3 group"
                  >
                    <span className="font-medium">{word.word}</span>
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => speak(word.word)}
                        className="text-gray-600 hover:text-purple-600"
                      >
                        <Volume2 className="w-4 h-4" />
                      </button>
                      {editingListId === list.id && (
                        <button
                          onClick={() => handleDeleteWord(list.id!, word.word)}
                          className="text-red-600 opacity-0 group-hover:opacity-100 transition-opacity"
                        >
                          <Trash2 className="w-4 h-4" />
                        </button>
                      )}
                    </div>
                  </motion.div>
                ))}
                {editingListId === list.id && !showBulkAddModal && (
                  <motion.button
                    layout
                    onClick={() => setShowBulkAddModal(true)}
                    className="flex items-center justify-center gap-2 p-3 bg-purple-50 text-purple-600 rounded-lg hover:bg-purple-100 transition-colors"
                  >
                    <Plus className="w-4 h-4" />
                    Add Words
                  </motion.button>
                )}
              </div>

              <AnimatePresence>
                {showBulkAddModal && editingListId === list.id && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    className="mt-4"
                  >
                    <div className="bg-purple-50 rounded-lg p-4">
                      <h3 className="font-medium text-purple-800 mb-2">Add Multiple Words</h3>
                      <p className="text-sm text-purple-600 mb-2">
                        Enter one word per line
                      </p>
                      <textarea
                        value={bulkWords}
                        onChange={(e) => setBulkWords(e.target.value)}
                        className="w-full h-32 rounded-lg border-gray-300 focus:ring-purple-500 focus:border-purple-500 mb-4"
                        placeholder="Example:
beautiful
because
different"
                      />
                      <div className="flex justify-end gap-2">
                        <button
                          onClick={() => setShowBulkAddModal(false)}
                          className="px-4 py-2 text-gray-600 hover:text-gray-800"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={() => handleBulkAddWords(list.id!)}
                          disabled={isProcessing}
                          className="flex items-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
                        >
                          {isProcessing ? (
                            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                          ) : (
                            <>
                              <Plus className="w-4 h-4" />
                              Add Words
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <AnimatePresence>
        {showNewListModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            onClick={resetForm}
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-white rounded-xl p-6 w-full max-w-md relative"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">New Word List</h2>
                <button
                  onClick={resetForm}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    List Title
                  </label>
                  <input
                    type="text"
                    value={newListTitle}
                    onChange={(e) => setNewListTitle(e.target.value)}
                    className="w-full rounded-lg border-gray-300 focus:ring-purple-500 focus:border-purple-500"
                    placeholder="e.g., Week 3 - Compound Words"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Test Date
                  </label>
                  <input
                    type="date"
                    value={newListDate}
                    onChange={(e) => setNewListDate(e.target.value)}
                    className="w-full rounded-lg border-gray-300 focus:ring-purple-500 focus:border-purple-500"
                  />
                </div>
                <div className="flex justify-end gap-2">
                  <button
                    onClick={resetForm}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleAddNewList}
                    className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                  >
                    Create List
                  </button>
                </div>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default WordLibrary;