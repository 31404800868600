import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Volume2, Check, X, ArrowRight, Trophy, BookOpen, Star, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';
import Confetti from 'react-confetti';
import { toast } from 'sonner';
import WordListSelector from '../components/WordListSelector';
import { useWordStore } from '../lib/wordStore';
import { useProgressStore } from '../lib/progressStore';
import { speak } from '../utils/speech';
import { triggerConfetti } from '../utils/confetti';
import type { Word } from '../lib/database.types';

export default function SpellingTest() {
  const { wordLists, selectedListId } = useWordStore();
  const { addTestResult } = useProgressStore();
  const [currentWord, setCurrentWord] = useState('');
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);
  const [showNextButton, setShowNextButton] = useState(false);
  const [correctCount, setCorrectCount] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [testWords, setTestWords] = useState<Word[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (selectedListId) {
      const list = wordLists.find(l => l.id === selectedListId);
      setTestWords(list?.words || []);
    } else {
      const allWords = wordLists.reduce<Word[]>((acc, list) => [...acc, ...list.words], []);
      setTestWords(allWords);
    }
    setCurrentWordIndex(0);
    setCurrentWord('');
    setIsCorrect(null);
    setShowNextButton(false);
    setCorrectCount(0);
    setShowResults(false);
  }, [wordLists, selectedListId]);

  const speakCurrentWord = () => {
    if (testWords[currentWordIndex]) {
      speak(testWords[currentWordIndex].word);
    }
  };

  const speakSentence = () => {
    if (testWords[currentWordIndex]) {
      speak(testWords[currentWordIndex].sentence);
    }
  };

  const checkAnswer = () => {
    if (!testWords[currentWordIndex]) return;
    
    const isWordCorrect = currentWord.toLowerCase().trim() === testWords[currentWordIndex].word.toLowerCase().trim();
    setIsCorrect(isWordCorrect);
    if (isWordCorrect) {
      setCorrectCount(prev => prev + 1);
    }
    setShowNextButton(true);
  };

  const finishTest = async () => {
    if (isProcessing) return;
    setIsProcessing(true);

    try {
      const finalCorrectCount = correctCount + (isCorrect ? 1 : 0);
      const percentage = Math.min(100, Math.round((finalCorrectCount / testWords.length) * 100));

      await addTestResult(finalCorrectCount, testWords.length, selectedListId || undefined);

      if (percentage >= 90) {
        triggerConfetti();
        toast.success('Outstanding achievement! 🌟');
      } else if (percentage >= 70) {
        toast.success('Well done! Keep practicing! 📚');
      }
      setShowResults(true);
    } catch (error) {
      console.error('Error saving test results:', error);
      toast.error('Failed to save results');
    } finally {
      setIsProcessing(false);
    }
  };

  const nextWord = async () => {
    const isLastWord = currentWordIndex === testWords.length - 1;
    
    if (isLastWord) {
      await finishTest();
    } else {
      setCurrentWordIndex(prev => prev + 1);
      setCurrentWord('');
      setIsCorrect(null);
      setShowNextButton(false);
    }
  };

  const getResultMessage = () => {
    const finalScore = (correctCount + (isCorrect ? 1 : 0)) / testWords.length;
    const percentage = Math.min(100, Math.round(finalScore * 100));
    
    if (finalScore >= 0.9) {
      return {
        title: "Outstanding Achievement! 🌟",
        message: `Incredible! You scored ${percentage}%! Your spelling skills are exceptional!`,
        color: "text-green-600",
        showConfetti: true,
        encouragement: "Ready for an even bigger challenge?"
      };
    } else if (finalScore >= 0.7) {
      return {
        title: "Well Done! 🎉",
        message: `Great job! You scored ${percentage}%! Keep up the good work!`,
        color: "text-green-600",
        showConfetti: false,
        encouragement: "Practice makes perfect!"
      };
    } else if (finalScore >= 0.5) {
      return {
        title: "Getting Better! 📚",
        message: `Nice effort! You scored ${percentage}%! You're making progress!`,
        color: "text-yellow-600",
        showConfetti: false,
        encouragement: "A bit more practice and you'll be a spelling champion!"
      };
    } else {
      return {
        title: "Keep Going! 💪",
        message: `You scored ${percentage}%. Remember: every champion started as a beginner!`,
        color: "text-purple-600",
        showConfetti: false,
        encouragement: "Let's review these words with flash cards and try again!"
      };
    }
  };

  if (testWords.length === 0) {
    return (
      <div className="container mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold text-purple-600 mb-4">Spelling Test</h1>
        <p className="text-xl text-gray-600 mb-8">No words available yet.</p>
        <Link
          to="/library"
          className="inline-flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <BookOpen className="w-5 h-5" />
          Add Words to Library
        </Link>
      </div>
    );
  }

  if (showResults) {
    const result = getResultMessage();
    return (
      <div className="container mx-auto px-4 py-12 text-center">
        {result.showConfetti && (
          <Confetti
            width={windowSize.width}
            height={windowSize.height}
            numberOfPieces={300}
            recycle={false}
            colors={['#7c3aed', '#4f46e5', '#2563eb', '#9333ea', '#ffd700']}
          />
        )}
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className="bg-white rounded-2xl shadow-xl p-8 max-w-2xl mx-auto"
        >
          <motion.div 
            className="flex justify-center mb-6"
            animate={{ rotate: result.showConfetti ? 360 : 0 }}
            transition={{ duration: 1, repeat: result.showConfetti ? Infinity : 0, ease: "linear" }}
          >
            {result.showConfetti ? (
              <Trophy className="w-20 h-20 text-yellow-400" />
            ) : (
              <Star className="w-20 h-20 text-purple-600" />
            )}
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
          >
            <h2 className={`text-3xl font-bold mb-4 ${result.color}`}>{result.title}</h2>
            <p className="text-xl text-gray-600 mb-4">{result.message}</p>
            <p className="text-lg text-purple-600 mb-8">{result.encouragement}</p>
            
            <div className="grid grid-cols-2 gap-4 mb-8">
              <div className="bg-purple-50 rounded-lg p-4">
                <div className="text-3xl font-bold text-purple-600">
                  {correctCount + (isCorrect ? 1 : 0)}/{testWords.length}
                </div>
                <div className="text-sm text-purple-600">Words Correct</div>
              </div>
              <div className="bg-blue-50 rounded-lg p-4">
                <div className="text-3xl font-bold text-blue-600">
                  {Math.round(((correctCount + (isCorrect ? 1 : 0)) / testWords.length) * 100)}%
                </div>
                <div className="text-sm text-blue-600">Score</div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <Link
                to="/dashboard"
                className="flex items-center justify-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors group"
              >
                <Trophy className="w-5 h-5 group-hover:scale-110 transition-transform" />
                View Progress
                <Sparkles className="w-5 h-5 animate-pulse" />
              </Link>
              <Link
                to="/flashcards"
                className="flex items-center justify-center gap-2 px-6 py-3 bg-purple-100 text-purple-600 rounded-lg hover:bg-purple-200 transition-colors"
              >
                <BookOpen className="w-5 h-5" />
                Practice with Flash Cards
              </Link>
            </div>
          </motion.div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-2xl mx-auto"
      >
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-purple-600 mb-4">Spelling Test</h1>
          <p className="text-xl text-gray-600 mb-8">Listen carefully and type what you hear!</p>
          <WordListSelector />
          <p className="text-gray-600">
            Word {currentWordIndex + 1} of {testWords.length}
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl p-8">
          <div className="flex justify-center gap-4 mb-8">
            <button
              onClick={speakCurrentWord}
              className="flex items-center gap-2 px-6 py-3 bg-purple-100 text-purple-600 rounded-full hover:bg-purple-200 transition-colors text-lg"
            >
              <Volume2 className="w-6 h-6" />
              Play Word
            </button>
            {isCorrect === false && (
              <button
                onClick={speakSentence}
                className="flex items-center gap-2 px-6 py-3 bg-purple-100 text-purple-600 rounded-full hover:bg-purple-200 transition-colors text-lg"
              >
                <Volume2 className="w-6 h-6" />
                Hear in Sentence
              </button>
            )}
          </div>

          <div className="space-y-6">
            <div>
              <input
                type="text"
                value={currentWord}
                onChange={(e) => setCurrentWord(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !showNextButton) {
                    checkAnswer();
                  } else if (e.key === 'Enter' && showNextButton) {
                    nextWord();
                  }
                }}
                className="w-full px-4 py-3 text-xl border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                placeholder="Type the word here..."
                autoFocus
              />
            </div>

            {showNextButton ? (
              <button
                onClick={nextWord}
                className="w-full py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors text-lg flex items-center justify-center gap-2"
              >
                {currentWordIndex === testWords.length - 1 ? (
                  <>
                    Finish Test
                    <Trophy className="w-5 h-5" />
                  </>
                ) : (
                  <>
                    Next Word
                    <ArrowRight className="w-5 h-5" />
                  </>
                )}
              </button>
            ) : (
              <button
                onClick={checkAnswer}
                className="w-full py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors text-lg"
              >
                Check Answer
              </button>
            )}

            {isCorrect !== null && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className={`flex items-center justify-center gap-2 p-4 rounded-lg ${
                  isCorrect ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
                }`}
              >
                {isCorrect ? (
                  <>
                    <Check className="w-5 h-5" />
                    <span>Correct! Well done!</span>
                  </>
                ) : (
                  <>
                    <X className="w-5 h-5" />
                    <span>The correct spelling is: {testWords[currentWordIndex].word}</span>
                  </>
                )}
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
}