import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../lib/supabase';
import LoadingSpinner from '../components/LoadingSpinner';
import { toast } from 'sonner';

export default function AuthCallback() {
  const navigate = useNavigate();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        const { error } = await supabase.auth.getSession();
        if (error) throw error;

        toast.success('Email confirmed successfully!');
        navigate('/dashboard');
      } catch (error: any) {
        console.error('Auth error:', error);
        toast.error(error.message || 'Authentication failed');
        navigate('/login');
      }
    };

    handleCallback();
  }, [navigate]);

  return <LoadingSpinner />;
}