import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Trophy, Target, Calendar, TrendingUp, Settings, Star } from 'lucide-react';
import { useAuth } from '../lib/supabase';
import { useProgressStore } from '../lib/progressStore';
import SpeechSettings from '../components/SpeechSettings';
import DatabaseTest from '../components/DatabaseTest';
import { format } from 'date-fns';

function Dashboard() {
  const { user } = useAuth();
  const { 
    weeklyScore, 
    wordsMastered, 
    streak, 
    improvement, 
    recentResults,
    isLoading,
    error,
    fetchProgress 
  } = useProgressStore();
  const [showSettings, setShowSettings] = React.useState(false);
  const firstName = user?.user_metadata?.first_name || 'Student';

  useEffect(() => {
    fetchProgress();
  }, [fetchProgress]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-purple-600 border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-8 flex justify-between items-center"
      >
        <div>
          <h1 className="text-3xl font-bold text-gray-800">Welcome back, {firstName}!</h1>
          <p className="text-gray-600">Track your spelling progress</p>
        </div>
        <button
          onClick={() => setShowSettings(!showSettings)}
          className="p-2 rounded-lg text-gray-600 hover:text-purple-600 hover:bg-purple-50 transition-colors"
          title="Voice Settings"
        >
          <Settings className="w-6 h-6" />
        </button>
      </motion.div>

      {error && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8 p-4 bg-red-50 text-red-600 rounded-lg"
        >
          {error}
        </motion.div>
      )}

      {showSettings && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-8"
        >
          <h2 className="text-xl font-bold mb-4">Voice Settings</h2>
          <SpeechSettings />
        </motion.div>
      )}

      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        {[
          { 
            icon: Trophy, 
            title: "Weekly Score", 
            value: `${weeklyScore}%`,
            color: "text-yellow-500",
            bg: "bg-yellow-50" 
          },
          { 
            icon: Target, 
            title: "Words Mastered", 
            value: wordsMastered.toString(),
            color: "text-green-500",
            bg: "bg-green-50"
          },
          { 
            icon: Calendar, 
            title: "Practice Streak", 
            value: `${streak} days`,
            color: "text-blue-500",
            bg: "bg-blue-50"
          },
          { 
            icon: TrendingUp, 
            title: "Improvement", 
            value: `${improvement > 0 ? '+' : ''}${improvement}%`,
            color: "text-purple-500",
            bg: "bg-purple-50"
          },
        ].map((stat, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className={`${stat.bg} rounded-xl p-6 shadow-lg`}
          >
            <stat.icon className={`w-8 h-8 ${stat.color} mb-4`} />
            <h3 className="text-gray-600 text-sm">{stat.title}</h3>
            <p className="text-2xl font-bold text-gray-800">{stat.value}</p>
          </motion.div>
        ))}
      </div>

      <div className="bg-white rounded-xl shadow-lg p-6">
        <h2 className="text-xl font-bold text-gray-800 mb-4">Recent Activity</h2>
        <div className="space-y-4">
          {recentResults.length === 0 ? (
            <p className="text-gray-600 text-center py-4">No test results yet. Start practicing to see your progress!</p>
          ) : (
            recentResults.map((result, index) => (
              <div key={index} className="flex items-center justify-between py-2 border-b last:border-0">
                <div>
                  <h3 className="font-medium text-gray-800">
                    {result.word_list_id ? 'List Practice' : 'All Words Practice'}
                  </h3>
                  <p className="text-sm text-gray-600">
                    {format(new Date(result.date), 'MMM d, yyyy')}
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <span className={`font-medium ${
                    (result.score / result.total_words) >= 0.7 
                      ? 'text-green-600' 
                      : (result.score / result.total_words) >= 0.5 
                      ? 'text-yellow-600' 
                      : 'text-red-600'
                  }`}>
                    {result.score}/{result.total_words}
                  </span>
                  {(result.score / result.total_words) >= 0.7 && (
                    <Star className="w-5 h-5 text-yellow-400 fill-current" />
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">System Status</h2>
        <DatabaseTest />
      </div>
    </div>
  );
}

export default Dashboard;