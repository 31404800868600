import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { HelpCircle, X, Send, ChevronRight, MessageCircle } from 'lucide-react';

interface FAQ {
  id: string;
  question: string;
  answer: string;
  keywords: string[];
  relatedQuestions?: string[];
}

const faqs: FAQ[] = [
  {
    id: 'login',
    question: "Having trouble logging in?",
    answer: "Make sure to:\n1. Check your email for the verification code\n2. Enter the 6-digit code exactly as shown\n3. If you don't receive the code, check your spam folder\n4. Try requesting a new code if needed",
    keywords: ['login', 'sign in', 'verification', 'code', 'email', 'password'],
    relatedQuestions: ['account', 'forgot-password']
  },
  {
    id: 'account',
    question: "How do I create an account?",
    answer: "To create an account:\n1. Click 'Login' in the top menu\n2. Enter your email and create a password\n3. Check your email for a verification code\n4. Enter the code to activate your account",
    keywords: ['account', 'register', 'sign up', 'create account'],
    relatedQuestions: ['login']
  },
  {
    id: 'forgot-password',
    question: "Forgot your password?",
    answer: "To reset your password:\n1. Click 'Login'\n2. Click 'Forgot Password'\n3. Enter your email\n4. Follow the reset instructions sent to your email",
    keywords: ['password', 'reset', 'forgot', 'change password'],
    relatedQuestions: ['login']
  },
  {
    id: 'word-lists',
    question: "How do I create a word list?",
    answer: "To create a word list:\n1. Go to 'Word Library'\n2. Click 'New List'\n3. Enter a title and test date\n4. Add words individually or use bulk add\n5. Your list will save automatically",
    keywords: ['list', 'words', 'create list', 'add words'],
    relatedQuestions: ['practice-test']
  },
  {
    id: 'practice-test',
    question: "How do practice tests work?",
    answer: "Practice tests help you prepare:\n1. Select a word list\n2. Listen to each word\n3. Type what you hear\n4. Get instant feedback\n5. Review your results\n\nTip: Use flash cards first to familiarize yourself with the words!",
    keywords: ['test', 'practice', 'exam', 'quiz'],
    relatedQuestions: ['word-lists', 'flash-cards']
  },
  {
    id: 'flash-cards',
    question: "How do I use flash cards?",
    answer: "Flash cards are interactive:\n1. Go to 'Flash Cards'\n2. Select a word list\n3. Click cards to flip them\n4. Use the speaker icon to hear words\n5. Navigate with arrow buttons\n\nPro tip: Practice with flash cards before taking tests!",
    keywords: ['flash cards', 'cards', 'practice', 'study'],
    relatedQuestions: ['practice-test', 'word-lists']
  },
  {
    id: 'achievements',
    question: "How do I earn achievements?",
    answer: "Earn achievements by:\n- Getting perfect scores on tests\n- Maintaining practice streaks\n- Mastering words\n- Creating custom sentences\n- Completing quick tests\n- Building your vocabulary\n\nCheck your profile to view earned achievements!",
    keywords: ['achievements', 'badges', 'rewards', 'progress'],
    relatedQuestions: ['practice-test', 'flash-cards']
  }
];

export default function HelpBot() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Array<{ type: 'user' | 'bot', content: string | FAQ }>>([
    { type: 'bot', content: "Hi! I'm here to help. What would you like to know about?" }
  ]);
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);

  const findRelevantFAQs = (query: string): FAQ[] => {
    const words = query.toLowerCase().split(' ');
    return faqs
      .filter(faq => 
        words.some(word => 
          faq.keywords.some(keyword => keyword.includes(word)) ||
          faq.question.toLowerCase().includes(word)
        )
      )
      .slice(0, 3);
  };

  const handleSend = () => {
    if (!inputValue.trim()) return;

    const userMessage = { type: 'user' as const, content: inputValue };
    const relevantFAQs = findRelevantFAQs(inputValue);
    
    setMessages(prev => [...prev, userMessage]);
    setInputValue('');
    setShowSuggestions(false);

    if (relevantFAQs.length > 0) {
      setTimeout(() => {
        setMessages(prev => [
          ...prev,
          { type: 'bot', content: relevantFAQs[0] }
        ]);
        if (relevantFAQs[0].relatedQuestions) {
          setShowSuggestions(true);
        }
      }, 500);
    } else {
      setTimeout(() => {
        setMessages(prev => [
          ...prev,
          { 
            type: 'bot', 
            content: "I'm not sure about that. Here are some topics I can help with:" 
          }
        ]);
        setShowSuggestions(true);
      }, 500);
    }
  };

  const handleFAQClick = (faq: FAQ) => {
    setMessages(prev => [
      ...prev,
      { type: 'bot', content: faq }
    ]);
    setShowSuggestions(true);
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed bottom-4 right-4 p-3 bg-purple-600 text-white rounded-full shadow-lg hover:bg-purple-700 transition-colors z-50"
      >
        <MessageCircle className="w-6 h-6" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed bottom-20 right-4 w-96 max-w-[calc(100vw-2rem)] bg-white rounded-lg shadow-xl overflow-hidden z-50"
          >
            {/* Header */}
            <div className="bg-purple-600 text-white p-4 flex justify-between items-center">
              <div className="flex items-center gap-2">
                <HelpCircle className="w-5 h-5" />
                <h3 className="font-semibold">Help & Support</h3>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className="text-white/80 hover:text-white transition-colors"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            {/* Messages */}
            <div className="h-96 overflow-y-auto p-4 space-y-4">
              {messages.map((message, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className={`flex ${message.type === 'user' ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`max-w-[80%] rounded-lg p-3 ${
                      message.type === 'user'
                        ? 'bg-purple-600 text-white'
                        : 'bg-gray-100 text-gray-800'
                    }`}
                  >
                    {typeof message.content === 'string' ? (
                      <p className="whitespace-pre-line">{message.content}</p>
                    ) : (
                      <div>
                        <h4 className="font-semibold mb-2">{message.content.question}</h4>
                        <p className="whitespace-pre-line text-sm">{message.content.answer}</p>
                      </div>
                    )}
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Suggestions */}
            {showSuggestions && (
              <div className="p-4 border-t border-gray-100">
                <p className="text-sm text-gray-600 mb-2">Common questions:</p>
                <div className="space-y-2">
                  {faqs.slice(0, 3).map((faq) => (
                    <button
                      key={faq.id}
                      onClick={() => handleFAQClick(faq)}
                      className="w-full text-left p-2 rounded-lg hover:bg-purple-50 transition-colors flex items-center justify-between group"
                    >
                      <span className="text-sm text-gray-700">{faq.question}</span>
                      <ChevronRight className="w-4 h-4 text-gray-400 group-hover:text-purple-600" />
                    </button>
                  ))}
                </div>
              </div>
            )}

            {/* Input */}
            <div className="p-4 border-t border-gray-100">
              <div className="flex gap-2">
                <input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSend();
                    }
                  }}
                  placeholder="Type your question..."
                  className="flex-1 px-3 py-2 border rounded-lg focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                />
                <button
                  onClick={handleSend}
                  className="p-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
                >
                  <Send className="w-5 h-5" />
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}