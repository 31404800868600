import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthProvider';
import { WordListProvider } from './components/WordListProvider';
import { ErrorBoundary } from './components/ErrorBoundary';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Toast from './components/Toast';
import HelpBot from './components/HelpBot';
import Home from './pages/Home';
import Demo from './pages/Demo';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AuthCallback from './pages/AuthCallback';
import FlashCards from './pages/FlashCards';
import SpellingTest from './pages/SpellingTest';
import WordLibrary from './pages/WordLibrary';
import HowToUse from './pages/HowToUse';
import About from './pages/About';
import Privacy from './pages/Privacy';

function App() {
  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AuthProvider>
          <WordListProvider>
            <div className="min-h-screen flex flex-col bg-gradient-to-b from-purple-50 to-blue-50">
              <Navbar />
              <Toast />
              <HelpBot />
              <main className="flex-grow">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/how-to-use" element={<HowToUse />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/demo" element={<Demo />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/auth/callback" element={<AuthCallback />} />
                  <Route path="/dashboard" element={
                    <AuthenticatedRoute>
                      <Dashboard />
                    </AuthenticatedRoute>
                  } />
                  <Route path="/flashcards" element={
                    <AuthenticatedRoute>
                      <FlashCards />
                    </AuthenticatedRoute>
                  } />
                  <Route path="/test" element={
                    <AuthenticatedRoute>
                      <SpellingTest />
                    </AuthenticatedRoute>
                  } />
                  <Route path="/library" element={
                    <AuthenticatedRoute>
                      <WordLibrary />
                    </AuthenticatedRoute>
                  } />
                </Routes>
              </main>
              <Footer />
            </div>
          </WordListProvider>
        </AuthProvider>
      </ErrorBoundary>
    </BrowserRouter>
  );
}

export default App;