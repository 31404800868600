import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import FlashCard from '../components/FlashCard';
import { ChevronLeft, ChevronRight, BookOpen } from 'lucide-react';
import WordListSelector from '../components/WordListSelector';
import { useWordStore, type Word } from '../lib/wordStore';
import { Link } from 'react-router-dom';

export default function FlashCards() {
  const { wordLists, selectedListId } = useWordStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentWords, setCurrentWords] = useState<Word[]>([]);

  useEffect(() => {
    if (selectedListId) {
      const list = wordLists.find(l => l.id === selectedListId);
      setCurrentWords(list?.words || []);
    } else {
      // Combine all words from all lists
      const allWords = wordLists.reduce<Word[]>((acc, list) => [...acc, ...list.words], []);
      setCurrentWords(allWords);
    }
    setCurrentIndex(0); // Reset index when words change
  }, [wordLists, selectedListId]);

  if (currentWords.length === 0) {
    return (
      <div className="container mx-auto px-4 py-12 text-center">
        <h1 className="text-4xl font-bold text-purple-600 mb-4">Flash Cards</h1>
        <p className="text-xl text-gray-600 mb-8">No words available yet.</p>
        <Link
          to="/library"
          className="inline-flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          <BookOpen className="w-5 h-5" />
          Add Words to Library
        </Link>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-bold text-purple-600 mb-4">Flash Cards</h1>
        <p className="text-xl text-gray-600 mb-8">Click the card to flip it!</p>
        <WordListSelector />
      </motion.div>

      <div className="max-w-2xl mx-auto">
        <div className="relative">
          <FlashCard
            word={currentWords[currentIndex].word}
            sentence={currentWords[currentIndex].sentence}
          />

          <div className="absolute top-1/2 -translate-y-1/2 -left-16">
            <button
              onClick={() => setCurrentIndex((prev) => (prev > 0 ? prev - 1 : currentWords.length - 1))}
              className="p-3 bg-white rounded-full shadow-lg hover:shadow-xl transition-shadow"
              aria-label="Previous card"
            >
              <ChevronLeft className="w-6 h-6 text-purple-600" />
            </button>
          </div>

          <div className="absolute top-1/2 -translate-y-1/2 -right-16">
            <button
              onClick={() => setCurrentIndex((prev) => (prev < currentWords.length - 1 ? prev + 1 : 0))}
              className="p-3 bg-white rounded-full shadow-lg hover:shadow-xl transition-shadow"
              aria-label="Next card"
            >
              <ChevronRight className="w-6 h-6 text-purple-600" />
            </button>
          </div>
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Card {currentIndex + 1} of {currentWords.length}
          </p>
        </div>
      </div>
    </div>
  );
}