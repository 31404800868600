import { create } from 'zustand';
import { supabase } from './supabase';
import type { Word, WordList } from './database.types';
import { useAuthStore } from './supabase';

interface WordStore {
  wordLists: WordList[];
  selectedListId: string | null;
  isLoading: boolean;
  error: string | null;
  setSelectedList: (id: string | null) => void;
  addWordList: (title: string, testDate: string) => Promise<string>;
  addWordsToList: (listId: string, words: string[]) => Promise<void>;
  deleteWordFromList: (listId: string, word: string) => Promise<void>;
  fetchWordLists: () => Promise<void>;
}

export const useWordStore = create<WordStore>((set, get) => ({
  wordLists: [],
  selectedListId: null,
  isLoading: false,
  error: null,

  setSelectedList: (id) => set({ selectedListId: id }),

  addWordList: async (title: string, testDate: string) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('Must be logged in to create word lists');

    try {
      const { data: list, error } = await supabase
        .from('word_lists')
        .insert({
          title,
          test_date: testDate,
          user_id: user.id,
          words: []
        })
        .select()
        .single();

      if (error) throw error;
      
      await get().fetchWordLists();
      return list.id;
    } catch (error: any) {
      console.error('Error adding list:', error);
      throw new Error('Failed to create word list');
    }
  },

  addWordsToList: async (listId: string, newWords: string[]) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('Must be logged in to add words');

    try {
      const { data: list, error: listError } = await supabase
        .from('word_lists')
        .select('words')
        .eq('id', listId)
        .single();

      if (listError) throw listError;

      const currentWords = list.words || [];
      const existingWords = new Set(currentWords.map((w: Word) => w.word.toLowerCase()));
      
      const wordsToAdd = newWords
        .filter(word => !existingWords.has(word.toLowerCase()))
        .map(word => ({
          word: word.trim(),
          sentence: `Example sentence with the word "${word.trim()}".`,
          created_at: new Date().toISOString()
        }));

      if (wordsToAdd.length === 0) {
        throw new Error('All words already exist in the list');
      }

      const { error: updateError } = await supabase
        .from('word_lists')
        .update({ 
          words: [...currentWords, ...wordsToAdd]
        })
        .eq('id', listId);

      if (updateError) throw updateError;

      await get().fetchWordLists();
    } catch (error: any) {
      console.error('Error adding words:', error);
      throw error;
    }
  },

  deleteWordFromList: async (listId: string, wordToDelete: string) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('Must be logged in to delete words');

    try {
      const { data: list, error: listError } = await supabase
        .from('word_lists')
        .select('words')
        .eq('id', listId)
        .single();

      if (listError) throw listError;

      const updatedWords = list.words.filter((w: Word) => w.word !== wordToDelete);

      const { error: updateError } = await supabase
        .from('word_lists')
        .update({ words: updatedWords })
        .eq('id', listId);

      if (updateError) throw updateError;

      await get().fetchWordLists();
    } catch (error: any) {
      console.error('Error deleting word:', error);
      throw error;
    }
  },

  fetchWordLists: async () => {
    const user = useAuthStore.getState().user;
    if (!user) {
      set({ wordLists: [], error: null, isLoading: false });
      return;
    }

    set({ isLoading: true, error: null });

    try {
      const { data: lists, error } = await supabase
        .from('word_lists')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;

      set({ 
        wordLists: lists || [], 
        isLoading: false 
      });
    } catch (error: any) {
      console.error('Error fetching lists:', error);
      set({ 
        error: 'Failed to fetch word lists', 
        isLoading: false 
      });
    }
  }
}));