import { createClient } from '@supabase/supabase-js';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import type { Database } from './database.types';

const supabaseUrl = 'https://hndefacdrqjlhigagdmj.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhuZGVmYWNkcnFqbGhpZ2FnZG1qIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzIzMDQ0NTMsImV4cCI6MjA0Nzg4MDQ1M30.0lcWGLvik1kHqgZYsW91rhUzURFlLYQdeaL7EUafw5s';

// Create Supabase client
export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'pkce'
  }
});

interface AuthState {
  user: any;
  isLoading: boolean;
  error: string | null;
  initialized: boolean;
}

interface AuthStore extends AuthState {
  setUser: (user: any) => void;
  setLoading: (loading: boolean) => void;
  setError: (error: string | null) => void;
  setInitialized: (initialized: boolean) => void;
  signOut: () => Promise<void>;
}

export const useAuthStore = create<AuthStore>()(
  persist(
    (set) => ({
      user: null,
      isLoading: true,
      error: null,
      initialized: false,
      setUser: (user) => set({ user }),
      setLoading: (isLoading) => set({ isLoading }),
      setError: (error) => set({ error }),
      setInitialized: (initialized) => set({ initialized }),
      signOut: async () => {
        try {
          await supabase.auth.signOut();
          set({ user: null });
        } catch (error: any) {
          set({ error: error.message });
        }
      }
    }),
    {
      name: 'auth-store',
      partialize: (state) => ({ user: state.user })
    }
  )
);

// Initialize auth state
supabase.auth.onAuthStateChange((event, session) => {
  const store = useAuthStore.getState();
  store.setUser(session?.user || null);
  store.setLoading(false);
  store.setInitialized(true);
});

// Export auth hook
export const useAuth = () => {
  const { user, isLoading, error, initialized } = useAuthStore();
  return { 
    user, 
    isLoading, 
    error,
    initialized,
    isAuthenticated: !!user
  };
};