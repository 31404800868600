import React from 'react';
import { motion } from 'framer-motion';
import { BookOpen, Heart, Star, Users, Code, Sparkles } from 'lucide-react';

export default function About() {
  const team = [
    {
      name: "Lily K",
      role: "Education Specialist",
      description: "Co-Creator current student.",
      avatar: "/team/lily.jpg"
    },
    {
      name: "Graham Kinsella",
      role: "Lead Developer",
      description: "Full-stack developer passionate about educational technology.",
      avatar: "/team/graham.jpg"
    },
    {
      name: "Bolt",
      role: "UX Designer",
      description: "Tool that made our dreams real.",
      avatar: "/team/bolt.jpg"
    }
  ];

  const values = [
    {
      icon: Star,
      title: "Excellence",
      description: "We strive for excellence in everything we do, from educational content to technical implementation."
    },
    {
      icon: Users,
      title: "Inclusivity",
      description: "Our platform is designed to be accessible and effective for learners of all abilities."
    },
    {
      icon: Heart,
      title: "Passion",
      description: "We're passionate about making learning fun and effective for every student."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-50 to-blue-50">
      <div className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <BookOpen className="w-16 h-16 text-purple-600 mx-auto mb-6" />
          <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">
            About Spell Jam
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            We're on a mission to make spelling practice engaging, effective, and enjoyable for every student
          </p>
        </motion.div>

        <div className="max-w-4xl mx-auto mb-16">
          <div className="bg-white rounded-xl shadow-lg p-8 mb-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Our Story</h2>
            <p className="text-gray-600 mb-4">
              Spell Jam was born from a simple observation: traditional spelling practice often feels like a chore for students. We believed there had to be a better way to learn—one that combines proven educational methods with modern technology and gamification.
            </p>
            <p className="text-gray-600">
              Working closely with educators, students, and parents, we developed a platform that makes spelling practice not just effective, but genuinely enjoyable. Our approach focuses on interactive learning, immediate feedback, and consistent progress tracking.
            </p>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Our Values</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl shadow-lg p-6 text-center"
              >
                <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <value.icon className="w-6 h-6 text-purple-600" />
                </div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">Meet Our Team</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl shadow-lg p-6"
              >
                <div className="w-24 h-24 mx-auto mb-4 relative">
                  <img
                    src={member.avatar}
                    alt={member.name}
                    className="w-full h-full rounded-full object-cover"
                    onError={(e) => {
                      const img = e.target as HTMLImageElement;
                      img.src = '/team/default-avatar.jpg';
                    }}
                  />
                </div>
                <h3 className="text-xl font-bold text-gray-800 mb-1 text-center">{member.name}</h3>
                <p className="text-purple-600 text-sm mb-2 text-center">{member.role}</p>
                <p className="text-gray-600 text-center">{member.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="bg-white rounded-xl shadow-lg p-8 text-center max-w-2xl mx-auto"
        >
          <Code className="w-12 h-12 text-purple-600 mx-auto mb-4" />
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Developed by BrandFinesse</h2>
          <p className="text-gray-600 mb-6">
            Spell Jam is proudly developed by BrandFinesse, combining educational expertise with cutting-edge technology to create an exceptional learning experience.
          </p>
          <a
            href="https://www.brandfinesse.com"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center gap-2 px-6 py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors group"
          >
            Visit BrandFinesse
            <Sparkles className="w-5 h-5 group-hover:scale-110 transition-transform" />
          </a>
        </motion.div>
      </div>
    </div>
  );
}