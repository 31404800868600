import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Cookie, Scale } from 'lucide-react';

export default function Privacy() {
  const sections = [
    {
      icon: Shield,
      title: "Data Protection",
      content: `We take the protection of your personal data seriously. This Privacy Policy explains how we collect, use, and protect your information when you use Spell Jam.

We collect:
- Account information (email, name)
- Learning progress data
- Usage statistics
- Device information

Your data is stored securely and is never sold to third parties.`
    },
    {
      icon: Lock,
      title: "Data Security",
      content: `We implement appropriate technical and organizational security measures to protect your personal information, including:

- Encryption of data in transit and at rest
- Regular security assessments
- Access controls and authentication
- Secure data backups`
    },
    {
      icon: Cookie,
      title: "Cookie Policy",
      content: `We use cookies and similar technologies to:

- Maintain your session
- Remember your preferences
- Analyze site usage
- Improve our services

You can control cookie settings through your browser preferences.`
    },
    {
      icon: Scale,
      title: "Your Rights (GDPR)",
      content: `Under GDPR, you have the right to:

- Access your personal data
- Correct inaccurate data
- Request data deletion
- Object to data processing
- Data portability
- Withdraw consent

Contact us to exercise these rights.`
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Privacy & Legal</h1>
        <p className="text-xl text-gray-600">
          Your privacy and data security are our top priorities
        </p>
      </motion.div>

      <div className="max-w-4xl mx-auto space-y-8">
        {sections.map((section, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-white rounded-xl shadow-lg p-6 md:p-8"
          >
            <div className="flex items-center gap-4 mb-4">
              <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center">
                <section.icon className="w-6 h-6 text-purple-600" />
              </div>
              <h2 className="text-2xl font-bold text-gray-800">{section.title}</h2>
            </div>
            <div className="prose max-w-none text-gray-600">
              {section.content.split('\n').map((paragraph, i) => (
                <p key={i} className="mb-4">{paragraph}</p>
              ))}
            </div>
          </motion.div>
        ))}

        <div className="bg-purple-50 rounded-xl p-6 md:p-8 text-center">
          <p className="text-gray-600 mb-4">
            For any privacy-related questions or to exercise your rights, please contact us at:
          </p>
          <a
            href="mailto:privacy@spelljam.com"
            className="text-purple-600 hover:text-purple-700 font-medium"
          >
            privacy@spelljam.com
          </a>
        </div>

        <div className="text-sm text-gray-500 text-center mt-8">
          Last updated: {new Date().toLocaleDateString()}
        </div>
      </div>
    </div>
  );
}