import { create } from 'zustand';
import { supabase } from './supabase';
import { useAuthStore } from './supabase';
import type { TestResult } from './database.types';

interface ProgressStore {
  weeklyScore: number;
  wordsMastered: number;
  streak: number;
  improvement: number;
  recentResults: TestResult[];
  isLoading: boolean;
  error: string | null;
  addTestResult: (score: number, totalWords: number, listId?: string) => Promise<void>;
  fetchProgress: () => Promise<void>;
}

export const useProgressStore = create<ProgressStore>((set, get) => ({
  weeklyScore: 0,
  wordsMastered: 0,
  streak: 0,
  improvement: 0,
  recentResults: [],
  isLoading: false,
  error: null,

  addTestResult: async (score: number, totalWords: number, wordListId?: string) => {
    const user = useAuthStore.getState().user;
    if (!user) throw new Error('Must be logged in to save progress');

    // Ensure score cannot exceed totalWords
    const validatedScore = Math.min(score, totalWords);

    try {
      const { error } = await supabase
        .from('test_results')
        .insert({
          user_id: user.id,
          score: validatedScore,
          total_words: totalWords,
          date: new Date().toISOString(),
          word_list_id: wordListId
        });

      if (error) throw error;

      await get().fetchProgress();
    } catch (error: any) {
      console.error('Error saving test result:', error);
      throw error;
    }
  },

  fetchProgress: async () => {
    const user = useAuthStore.getState().user;
    if (!user) {
      set({ 
        weeklyScore: 0,
        wordsMastered: 0,
        streak: 0,
        improvement: 0,
        recentResults: [],
        error: null,
        isLoading: false 
      });
      return;
    }

    set({ isLoading: true, error: null });

    try {
      // Get recent test results
      const { data: results, error } = await supabase
        .from('test_results')
        .select('*')
        .eq('user_id', user.id)
        .order('date', { ascending: false })
        .limit(10);

      if (error) throw error;

      // Calculate statistics
      const weekStart = new Date();
      weekStart.setDate(weekStart.getDate() - 7);
      
      const weeklyResults = (results || []).filter(r => 
        new Date(r.date) >= weekStart
      );
      
      // Calculate weekly average score (as percentage)
      const weeklyScore = weeklyResults.length > 0
        ? Math.min(100, Math.round(weeklyResults.reduce((acc, r) => 
            acc + ((r.score / r.total_words) * 100), 0) / weeklyResults.length))
        : 0;

      // Count words mastered (90% or better accuracy)
      const wordsMastered = (results || []).reduce((acc, r) => 
        acc + (r.score >= Math.floor(r.total_words * 0.9) ? r.total_words : 0), 0);

      // Calculate streak
      let streak = 0;
      const today = new Date().setHours(0, 0, 0, 0);
      for (let i = 0; i < (results || []).length; i++) {
        const resultDate = new Date(results[i].date).setHours(0, 0, 0, 0);
        if (today - resultDate > (i + 1) * 24 * 60 * 60 * 1000) break;
        streak++;
      }

      // Calculate improvement (percentage points)
      const improvement = weeklyResults.length >= 2
        ? Math.min(100, Math.max(-100, Math.round(
            ((weeklyResults[0].score / weeklyResults[0].total_words) -
            (weeklyResults[weeklyResults.length - 1].score / weeklyResults[weeklyResults.length - 1].total_words)) * 100
          )))
        : 0;

      set({
        weeklyScore,
        wordsMastered,
        streak,
        improvement,
        recentResults: results || [],
        error: null,
        isLoading: false
      });
    } catch (error: any) {
      console.error('Error fetching progress:', error);
      set({
        weeklyScore: 0,
        wordsMastered: 0,
        streak: 0,
        improvement: 0,
        recentResults: [],
        error: error.message || 'Failed to fetch progress',
        isLoading: false
      });
    }
  }
}));